<template src="./SearchMasterManagementPage.html"></template>
<style scoped lang="scss" src="./SearchMasterManagementPage.scss"></style>

<script>
import _ from "lodash";
import {MasterDataTypes, FilterData, FilterLimit} from "@/constants";
import {ServiceFactory, eventBusService} from "@/services";

const LimitFilter = () => import('@/components/limit-filter/LimitFilter')

const MasterDataService = ServiceFactory.get('masterData');
const DefaultFilterData = {
  type: '',
  sort: '',
  order: '',
  page: FilterData.Page,
  limit: FilterLimit.firstLimit
};
export default {
  name: 'SearchMasterManagementPage',
  components: { LimitFilter },
  data: function () {
    return {
      isLoading: false,
      isShowDeleteDialog: false,
      isDeleting: false,
      masterDataDeleteId: null,
      filter: null,
      filterData: _.cloneDeep(DefaultFilterData),
      table: {
        fields: [
          { isSortAble: 'custom' , prop: 'type', label: 'マスタのタイプ' },
          { isSortAble: 'custom' , prop: 'name', label: '内容' },
        ],
        items: [],
        total: 0
      },
      masterDataTypeOptions: MasterDataTypes,
    }
  },
  created() {
    eventBusService.$on(eventBusService.SHOW_DELETE_MODAL_SUBMIT, this.deleteOrder)
    this.init()
  },
  methods: {
    clickToSearchButton() {
      this.filter()
    },

    clickToResetButton() {
      this.filterData = _.cloneDeep(DefaultFilterData)
      this.filter()
    },

    clickToCreateOrderButton() {
      this.$router.push({ name: 'routes.masterManagement.create' })
    },

    clickToDeleteButton(orderIndex) {
      const item = this.table.items.find((item, index) => index === orderIndex) || null
      if (!item || !item.id) return

      this.masterDataDeleteId = item.id
      this.isShowDeleteDialog = true

      eventBusService.$emit(eventBusService.SHOW_DELETE_MODAL, {
        data: this.masterDataDeleteId,
        title: 'マスタを削除',
        message: 'このデータを削除してもよろしいでしょうか？',
        subMessage: '削除した後、元に戻すことができません。'
      })
    },

    clickToCancelDeleteButton() {
      this.masterDataDeleteId = null
      this.isShowDeleteDialog = false

    },

    clickToConfirmDeleteButton() {
      this.deleteOrder()
    },

    clickToEditButton(orderIndex) {
      const item = this.table.items.find((item, index) => index === orderIndex) || null
      if (!item || !item.id) return

      this.$router.push({ name: 'routes.masterManagement.edit', params: { id: item.id } })
    },

    onCurrentPageChange(page) {
      this.filterData.page = page
      this.filter()
    },

    onLimitChange(limit) {
      this.filterData.limit = limit
      this.filterData.page = 1
      this.filter()
    },

    init() {
      this.filter = _.debounce(() => this.search(), FilterData.Debounce)
      this.filter()
    },

    async search() {
      this.isLoading = true
      const res = await MasterDataService.search(this.filterData).catch(() => ({total: 0, items: []}));
      this.isLoading = false

      this.table.items = res.items || []
      this.table.total = res.total || 0
    },

    async deleteOrder() {
      if (!this.masterDataDeleteId) return

      this.isDeleting = true
      const error = await MasterDataService.delete(this.masterDataDeleteId).catch((error) => error)
      this.isDeleting = false

      if (error) return

      this.masterDataDeleteId = null
      this.isShowDeleteDialog = false
      this.filterData.page = 1

      this.$notify({
        message: '削除しました。',
        type: 'success'
      })

      eventBusService.$emit(eventBusService.HIDE_DELETE_MODAL)

      return this.filter()
    },

    indexMethod(index) {
      return index + 1 + (this.filterData.page - 1) * this.filterData.limit;
    },

    sortChange(column) {
      this.filterData.sort = column.prop;
      this.filterData.order = column.order === 'ascending' ? 'asc' : 'desc';
      this.filter()
    },
  }
}
</script>
